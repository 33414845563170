<template>
  <Footer class="bottom">
    <!-- 左边数据 -->
    <div class="company-introduce w-[270px]">
      <div class="flex flex-col">
        <img class="img" src="@/assets/FSK.png" />
        <div class="titleText cursor-default">{{ title }}</div>
      </div>
      <div class="fontArial cursor-default mt-3 w-[250px]">工业智能化解决方案服务商</div>
      <div class="mt-4 w-full flex items-center">
        <img style="width: 32px" src="@/assets/phone.png" />
        <span class="fontArial cursor-default w-[250px]">客服电话</span>
      </div>
      <div class="phone mt-4 cursor-default">400-892-5001</div>
    </div>
    <!-- 中间菜单数据 -->
    <div class="centerBox">
      <div class="w-full flex justify-between">
        <div
          v-for="(item, index) in levelTitleArr"
          :key="index"
          class="bottom-1"
        >
          <!-- 一级菜单 -->
          <div class="fontArial fs18">{{ item.title }}</div>
          <!-- 二级菜单 -->
          <div
            v-for="(v, i) in item.SecondaryTitle"
            :key="i"
            class="fontArial fs14 cursor"
            @click="goPage(v)"
          >
            {{ v.title }}
          </div>
        </div>
      </div>
      <div class="fontArial" style="font-size: 18px">
        ©2004-2022 {{ title }} (青岛) 智能科技有限公司 版权所有
        鲁ICP备2024085098号
      </div>
    </div>
    <!-- 右边二维码 -->
    <div class="contact-company w-[270px]">
      <div class="fontArial fs18 text-center">关注{{ title }}</div>
      <img style="width: 120px" src="@/assets/QRcode.png" />
      <div class="fontArial fs18 text-center">{{ title }}官方微信</div>
    </div>
  </Footer>
</template>

<script>
import { MainTitle, levelTitle } from "@/constant/index.js";
export default {
  name: "FskFooter",

  data() {
    return {
      title: MainTitle,
      levelTitleArr: JSON.parse(JSON.stringify(levelTitle)),
      search: "",
    };
  },

  mounted() {
    // 删除首页
    this.levelTitleArr.shift();
  },

  methods: {
    // 导航栏切换
    goPage(item) {
      // 根据路由高亮tag
      const { level, router, parentRouter } = item;
      this.currentPath = level == 1 ? router : parentRouter; //激活样式
      // 路由跳转
      item.type != ""
        ? this.$router.push({ path: item.router, query: { type: item.type } })
        : this.$router.push({ path: item.router });
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom {
  width: 100%;
  height: 100%;
  background-color: #555555;
  color: #fff;
  padding: 20px 16px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  &:hover{
    cursor: default;
  }

  .company-introduce {
    padding-right: 16px;
    border-right: 1px solid #ccc;
  }

  .img {
    width: 80px;
    height: 21px;
    & + div{
      width: 80px;
      text-align: center;
    }
  }

  .bottom-1 {
    width: calc(100% / 5);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* 分割线 */
  .divider {
    height: 100%;
    border: 1px solid #fff;
  }

  /* 字体 */
  .fontArial {
    font-family: "Arial";
    font-size: 20px;
    font-weight: 600;
  }

  .phone {
    width: max-content;
    font-size: 36px;
    font-weight: 800;
  }

  .contact-company {
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: 1px solid #ccc;
  }

  .fs18 {
    font-size: 18px;
    height: 40px;
    line-height: 40px;
  }

  .fs14 {
    font-size: 14px;
    height: 30px;
    line-height: 30px;
  }

  .centerBox {
    padding: 0 16px;
    width: calc(100% - (270px * 2));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}
.cursor {
    &:hover {
      cursor: pointer;
    }
  }
.cursor-default {
    &:hover {
      cursor: default;
    }
  }
</style>
