<template>
  <div id="app">
    <Layout />
  </div>
</template>

<script>
import Layout from "@/layout/index";
export default {
  name: 'FskApp',
  components: {
    Layout
  },
  data() {
    return {

    };
  },

  mounted() {

  },

  methods: {

  },
};
</script>


<style lang="scss" scoped>
#app {
  width: 100%;
  height: 100%;
}
</style>