<template>
  <el-dialog
    title="在线申请"
    center
    :visible.sync="visible"
    width="40%"
    @close="close()"
  >
    <el-form :model="formData" ref="form" :rules="rules">
      <el-form-item prop="phone">
        <el-input
          v-model="formData.phone"
          placeholder="请输入手机号"
          prefix-icon="el-icon-phone-outline"
        ></el-input>
      </el-form-item>
      <el-form-item prop="name">
        <el-input
          v-model="formData.name"
          placeholder="请输入姓名"
          prefix-icon="el-icon-user"
        ></el-input>
      </el-form-item>
      <el-form-item prop="companyName">
        <el-input
          v-model="formData.companyName"
          placeholder="请输入企业名称"
          prefix-icon="el-icon-office-building"
        ></el-input>
      </el-form-item>
      <el-form-item prop="mailbox">
        <el-input
          v-model="formData.mailbox"
          placeholder="请输入邮箱"
          prefix-icon="el-icon-message"
        ></el-input>
      </el-form-item>
      <el-form-item prop="profession">
        <el-input
          v-model="formData.profession"
          placeholder="请输入行业"
        ></el-input>
      </el-form-item>
    </el-form>
    <!-- foorter -->
    <span slot="footer" class="dialog-footer">
      <el-button @click="close()">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="submit">提 交</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: "SubscribeDialog",

  props: {
    dialogFormVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      loading: false,
      formData: {},
      // 表单校验
      rules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        companyName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        profession: [{ required: true, message: "请输入行业", trigger: "blur" }],
        mailbox: [
          {
            pattern: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
            message: "请输入正确的邮箱",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    dialogFormVisible: function (newValue) {
      this.visible = newValue;
    },
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("/api/tbApply/tbApply/save", this.formData)
            .then((response) => {
              // 请求成功时的处理
              this.$message.success(response.msg);
              this.close();
            })
            .catch((error) => {
              // 请求失败时的处理
              console.error(error);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    close() {
      //关闭弹窗
      this.$refs.form.clearValidate();
      this.visible = false;
      this.$emit("closeDialog");
    },
  },
};
</script>
