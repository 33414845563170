<template>
  <Header>
    <div class="top">
      <div class="logo">
        <img class="img" src="@/assets/FSK.png" />
        <div class="titleText cursor-default">{{ title }}</div>
      </div>
      <div class="titie">
        <!-- 一级标题 -->
        <div class=" flex justify-around items-center" style="width: calc(100% - 250px);">
          <div v-for="(item, index) in  levelTitleArr " :key="index"
            :class="{ active: item.router == currentPath, FirstLevelTitle: true, showSecondTitle: item.isSecondaryTitle }"
            @click="goPage(item, index)">
            {{ item.title }}
            <div class="SecondaryTitle">
              <div v-for="( v, i ) in  item.SecondaryTitle " :key="i" @click.stop="goPage(v, index)">{{ v.title }}</div>
            </div>
          </div>
        </div>
        <!-- 搜素框 -->
        <div class=" mx-[10px] w-[230px] flex items-center">
          <el-input placeholder="请输入关键字" v-model="search">
            <el-button slot="append" icon="el-icon-search" @click="clickSearch"></el-button>
          </el-input>
          <!-- <el-input class="searchInput" v-model="search" placeholder="请输入关键字"></el-input>
          <el-button type="primary" icon="el-icon-search"></el-button> -->
        </div>
      </div>
    </div>
  </Header>
</template>

<script>
import { MainTitle, levelTitle } from "@/constant/index.js";

export default {
  name: "FskHeader",
  data() {
    return {
      title: MainTitle,
      levelTitleArr: JSON.parse(JSON.stringify(levelTitle)),
      search: "",
      currentPath: window.location.pathname.split('/').at(1),
    };
  },
  created() {
    const self = this;
    this.$watch('$route', function (newVal) {
      self.currentPath = newVal.path; // 监听路由判断展示
    }, { immediate: true });
  },
  mounted() {
  },
  methods: {
    // 导航栏切换
    goPage(item, index) {
      // 根据路由高亮tag
      const { level, title, router, parentRouter, isSecondaryTitle } = item;
      this.currentPath = level == 1 ? router : parentRouter; //激活样式

      if (title == '首页') {
        return this.$router.push('/');
      }
      // 如果点击的是二级路由，携带type
      if (level == 2) {
        item.type != '' ? this.$router.push({ path: item.router, query: { type: item.type } }) : null
      }

      if (level == 1 && !isSecondaryTitle) {
        item.router != '' ? this.$router.push({ path: item.router }) : null
      }
    },
    // 搜索
    clickSearch() {
      this.$router.push({ path: '/message', query: { search: this.search } })
      this.search = ''
    }
  },
};
</script>

<style lang="scss" scoped>
.top {
  width: 100%;
  height: 55px;
  background-color: #555555;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .img {
    width: 80px;
    height: 21px;
  }

  .titleText {
    color: #fff;
    font: -webkit-small-control;
  }

  .logo {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* 搜素按钮 */
  ::v-deep .el-input-group__append {
    background-color: #009b4c;
    border: #009b4c;
  }

  ::v-deep .el-input-group__append .el-button {
    color: #fff;
    font-size: 14px;
  }

  /* 搜素和标题 */
  .titie {
    width: calc(100% - 600px);
    min-width: 1200px;
    display: flex;
    align-items: center;
  }

  /* 搜素输入框 */
  .searchInput {
    >>>.el-input--small .el-input__inner {
      height: 35px;
    }

    .el-input--small .el-input__inner {
      height: 35px;
    }
  }

  /* 一级标题 */
  .FirstLevelTitle {
    width: calc(100% / 6);
    height: 55px;
    color: #fff;
    text-align: center;
    font: 13px "Arial";
    font-weight: 800;
    line-height: 55px;

    &:hover {
      cursor: pointer;
      background-color: #4b7902;
    }

    .SecondaryTitle {
      display: none;
      position: relative;
      z-index: 9999;
      background: #D7D7D7;
      color: #4B7902;
      width: 100%;

      &>div {
        height: 42px;
        line-height: 42px;
      }

      div:hover {
        background-color: #67c23a
      }
    }
  }

  .showSecondTitle:hover .SecondaryTitle {
    display: block
  }

  /* 一级标题被选择时 */
  .active {
    /*激活样式*/
    background-color: #4b7902;
  }

  :deep(.el-button--primary) {
    background-color: #0b9c53;
    border: none;
    width: 53px;
    height: 35px;
  }
}

.cursor-default {
    &:hover {
      cursor: default;
    }
  }
</style>
