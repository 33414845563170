<template>
  <div id="home">
    <div class="header">
      <Header></Header>
    </div>
    <div class="scroll-container">
      <div class="view">
        <router-view :key="key" />
      </div>
      <div class="footer">
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'FskHome',
  components: {
    Header,
    Footer
  },
  data() {
    return {

    };
  },
  computed: {
  key() {
    return this.$route.name !== undefined? this.$route.name +new Date(): this.$route +new Date()
  }
},
  mounted() {

  },

  methods: {

  },
};
</script>

<style lang="scss" scoped>
#home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    height: 55px;
  }

  .scroll-container {
    height: calc(100% - 55px);
    overflow: auto;

    .view {
      width: 100%;
      min-height: 100%;
    }

    .footer {
      width: 100%;
      height: 240px;
    }
  }

}
</style>
