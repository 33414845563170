import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './global/css/index.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import HttpService from '@/constant/http';

Vue.use(ElementUI, { size: 'small', zIndex: 3000 });
Vue.config.productionTip = false;

// 将axios挂载为app的全局自定义属性之后，每个组件可以通过this直接访问到全局挂载的自定义属性。
// $http是自定义属性名称。
Vue.prototype.$http = HttpService

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
