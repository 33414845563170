// 全局变量
const MainTitle = "富世科";
// 菜单
const levelTitle = [
  {
    level: 1,
    title: "首页",
    router: "/",
    isSecondaryTitle: false,
  },
  {
    level: 1,
    title: "产品服务",
    router: "/product",
    isSecondaryTitle: true,
    SecondaryTitle: [
      {
        level: 2,
        title: "蚂蚁工厂 - MOM平台",
        parentRouter: "/product",
        router: "/product",
        type: 'mom',
      },
      {
        level: 2,
        title: "蚂蚁物联 - IIoT平台",
        parentRouter: "/product",
        router: "/product",
        type: 'iiot',
      },
      {
        level: 2,
        title: "蚁行云上 - SaaS产品",
        parentRouter: "/product",
        router: "/product",
        type: 'saas',
      },
    ],
  },
  {
    level: 1,
    title: "客户案例",
    router: "/case",
    isSecondaryTitle: false,
    SecondaryTitle: [{ level: 2, title: "项目案例", router: "/case" }],
  },
  {
    level: 1,
    title: "新闻资讯",
    router: "/message",
    isSecondaryTitle: true,
    SecondaryTitle: [
      {
        level: 2,
        title: "公司新闻",
        parentRouter: "/message",
        router: "/message",
        type: "corp"
      },
      {
        level: 2,
        title: "行业资讯",
        parentRouter: "/message",
        router: "/message",
        type: "industry"
      },
    ],
  },
  {
    level: 1,
    title: "走进企业",
    router: "/enterprise",
    isSecondaryTitle: true,
    SecondaryTitle: [
      {
        level: 2,
        title: "公司概况",
        parentRouter: "/enterprise",
        router: "/enterprise",
        type: "introduce"
      },
      {
        level: 2,
        title: "企业文化",
        parentRouter: "/enterprise",
        router: "/enterprise",
        type: "culture"
      },
      {
        level: 2,
        title: "发展历程",
        parentRouter: "/enterprise",
        router: "/enterprise",
        type: "course"
      },
      {
        level: 2,
        title: "企业荣誉",
        parentRouter: "/enterprise",
        router: "/enterprise",
        type: "honor"
      },
    ],
  },
  {
    level: 1,
    title: "加入我们",
    router: "/joinUs",
    isSecondaryTitle: false,
    SecondaryTitle: [{ level: 2, title: "招聘岗位", router: "/joinUs" }],
  },
];
export { MainTitle, levelTitle };
