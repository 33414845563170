<template>
  <div>
    <!-- 轮播图 -->
    <el-carousel :initial-index="1" :interval="10000">
      <el-carousel-item v-for="(item, index) in carouselImg" :key="index">
        <img class="img" :src="item" />
      </el-carousel-item>
    </el-carousel>
    <div class="middleBox">
      <!-- 核心服务 start -->
      <div class="titleBox">
        <div class="h1">核心服务</div>
        <div class="h6">数据赋能，智创未来</div>
      </div>
      <!-- 产品服务tab -->
      <el-tabs v-model="serveTabIndex">
        <el-tab-pane v-for="(v, i) in serveData" :key="i">
          <span slot="label"
            ><img
              class="tabImg"
              :style="{ opacity: i == serveTabIndex ? 1 : 0.7 }"
              :src="require(`../assets/${v.tabImg}.png`)"
          /></span>
          <div class="bgGreen">
            <img class="MOMimg" :src="require(`../assets/${v.img}`)" />
            <div class="textBox">
              <div>{{ v.name }}</div>
              <div>{{ v.introduction }}</div>
              <div>
                {{ v.core }}
                <div class="worksBox">
                  <div v-for="(item, index) in v.worksArr" :key="index">
                    {{ item }}
                  </div>
                </div>
              </div>
              <div @click="goProduct(v.type, v.router)">深入了解产品></div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <!-- 核心服务 end -->
      <!-- 项目案例 start  -->
      <div class="titleBox">
        <div class="h1">项目案例</div>
        <div class="h6">持续服务，共赢未来</div>
      </div>
      <div class="caseBox">
        <el-skeleton :loading="CaseDataLoading" animated :count="4">
          <template slot="template">
            <el-skeleton-item
              variant="image"
              style="width: 320px; height: 267px; margin: 20px 60px"
            />
          </template>
        </el-skeleton>
        <div class="projectBox" v-if="projectArr.length > 0">
          <div
            v-for="(v, i) in projectArr"
            :key="i"
            @click="dialogFormVisible = true"
          >
            <div class="project">
              <div>
                <img
                  class="img"
                  :style="{ width: v.logoWidth }"
                  :src="v.titileImg"
                />
                <div>{{ v.smallTitle }}</div>
              </div>
              <div>{{ v.title }}</div>
              <img :src="v.contentImg" />
            </div>
          </div>
        </div>
        <el-empty
          v-if="projectArr.length == 0 && CaseDataLoading == false"
          description="暂无案例"
        ></el-empty>
        <!-- 查看更多 -->
        <div class="moreBox">
          <div class="more" @click="goCase()">查看更多案例></div>
        </div>
      </div>
      <!-- 项目案例 end  -->
      <!-- 新闻咨讯 start-->
      <div class="titleBox">
        <div class="h1">新闻咨讯</div>
        <div class="h6">与您同行，共创未来</div>
      </div>
      <!-- 骨架屏 -->
      <el-skeleton :rows="6" animated :loading="MessageDataLoading" />
      <div v-if="newsArr.length > 0">
        <div
          class="newsBox"
          v-for="(item, index) in newsArr"
          :key="index"
          @click="goMeassageDetails(item)"
        >
          <div class="newsTitle">
            <div>{{ item.title }}</div>
            <div>{{ item.sourceTime }}</div>
          </div>
          <div class="newsText">
            <img
              v-if="item.img"
              :src="item.img"
              class="w-44"
            />
            <div>
              <p class="ellipsis" :title="item.message">{{ item.message }}</p>
            </div>
          </div>
        </div>
      </div>
      <el-empty
        v-if="newsArr.length == 0 && MessageDataLoading == false"
        description="暂无信息"
      ></el-empty>
      <!-- 查看更多 -->
      <div class="moreBox">
        <div class="more" @click="goMessage()">查看更多咨讯></div>
      </div>
      <!-- 新闻咨讯 end-->
    </div>
    <SubscribeDialog
      :dialogFormVisible="dialogFormVisible"
      @closeDialog="dialogFormVisible = false"
    />
  </div>
</template>

<script>
import SubscribeDialog from "@/components/SubscribeDialog.vue";
export default {
  components: {
    SubscribeDialog,
  },
  data() {
    return {
      // 产品服务tab
      serveTabIndex: 0,
      // 产品服务数据
      serveData: [
        {
          name: "蚂蚁工厂 - MOM平台",
          type: "mom",
          router: "/product",
          tabImg: "u180",
          img: "mom.png",
          introduction:
            "以企业卓越运营管理为理念，融合精益管理思想，自主设计研发CRM、SRM、PLM、PMS、MES、QMS、WMS、TMS、AMS、EAM、Andon等应用，涵盖从制造的销售管理、采购管理、研发设计、计划统筹、生产制造、质量管理、资产管理、仓储物流等全业务的微服务应用，搭建企业生产运营一体化智能运营管理平台，满足多场景精细化管理，帮助企业实现智能协同制造。",
          worksArr: [
            "工艺管理",
            "计划管理",
            "制造执行",
            "质量控制",
            "仓储管理",
            "设备管理",
          ],
          core: "核心功能",
        },
        {
          name: "蚂蚁物联 - IIoT平台",
          type: "iiot",
          router: "/product",
          tabImg: "u185",
          img: "iiot.png",
          introduction:
            "通过边缘层、平台层、应用层一体化的物联能力，实现多源设备的快速接入、数据实时采集与边缘计算、设备数字孪生、可视化呈现和物联应用场景快速搭建，构建DNC数控传输、MDC数据采集、EMS能源管理、ICS工业控制、AGVS物流调度、设备智能巡检与远程运维等应用，打通业务系统与设备数据的互联融合，实现智能控制。",
          worksArr: [
            "数控传输",
            "数据采集",
            "工业控制",
            "AGC调度",
            "能源管理",
            "数字孪生",
          ],
          core: "核心功能",
        },
        {
          name: "蚁行云上 - SaaS平台",
          type: "saas",
          router: "/product",
          tabImg: "u186",
          img: "saas.png",
          introduction:
            "工业系统应用云端部署，分散式部署，各系统可以独立运行，也可以数据打通。客户可以根据业务实际需求选择合适的产品，无需关心软件的安装、升级、维护等问题，直接登录使用。",
          worksArr: [
            "生产管理系统（MES）",
            "质量管理系统（QMS）",
            "仓储管理系统（WMS）",
            "客户管理系统（CRM）",
            "供应商管理系统（SRM）",
            "设备管理系统（EAM）",
            "能源管理系统（EMS）",
            "异常管理系统（Andon）",
          ],
          core: "核心产品",
        },
      ],
      // 轮播图图片名称数组
      carouselImg: ["http://www.fsk-qd.com:9000/img/a4.jpg", "http://www.fsk-qd.com:9000/img/a6.jpg", "http://www.fsk-qd.com:9000/img/a5.jpg"],
      // 项目案例数据
      projectArr: [],
      // 新闻咨询数据
      newsArr: [],
      MessageDataLoading: false,
      CaseDataLoading: false,
      // 在线申请弹窗
      dialogFormVisible: false,
    };
  },

  mounted() {
    // 获取案例和新闻数据
    this.getCaseData(), this.getMessageData();
  },

  methods: {
    // 产品-深入了解
    goProduct(type, router) {
      this.$router.push({ path: router, query: { type: type } });
    },
    // 更多案例
    goCase() {
      this.$router.push({ path: "/case" });
    },
    // 更多新闻
    goMessage() {
      this.$router.push({ path: "/message", query: { type: "corp" } });
    },
    // 案例数据
    getCaseData() {
      this.CaseDataLoading = true;
      this.$http
        .post("/api/tbClientCase/tbClientCase/listByCondition", {})
        .then((res) => {
          const { code, data = [] } = res || {};
          if (code == 0)
            this.projectArr =
              data.length > 8 ? data.slice(0, 8) : data.slice(0, data.length);
        })
        .catch((error) => {
          // 请求失败时的处理
          console.error(error);
        })
        .finally(() => {
          this.CaseDataLoading = false;
        });
    },
    // 新闻数据
    getMessageData() {
      this.MessageDataLoading = true;
      this.$http
        .post("/api/tbMessage/tbMessage/listByCondition", { isInfo: 0 })
        .then((res) => {
          const { code, data = [] } = res || {};
          if (code == 0)
            this.newsArr =
              data.length > 5 ? data.slice(0, 5) : data.slice(0, data.length);
        })
        .catch((error) => {
          // 请求失败时的处理
          console.error(error);
        })
        .finally(() => {
          this.MessageDataLoading = false;
        });
    },
    // 新闻详情
    goMeassageDetails(item) {
      this.$router.push({
        name: "messageDetails",
        params: { clickData: item },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.img {
  width: 100%;
  height: 100%;
}

::v-deep .el-carousel__container {
  height: calc(100vh - 55px);
  min-height: 856px;
}

.screen-container {
  height: calc(100vh - 55px);
  min-height: 856px;
}

.middleBox {
  .titleBox,
  .moreBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;

    .h1,
    .more {
      width: 230px;
      text-align: center;
      color: #fff;
      background: #548235;
      font-size: 40px;
      font-weight: bold;
    }

    .h6 {
      height: 42px;
      line-height: 42px;
      font-weight: bold;
    }

    .more {
      margin-top: 20px;
      padding: 10px;
      font-size: 16px;

      &:hover {
        cursor: pointer;
      }
    }
  }
  .moreBox {
    margin-top: 10px;
  }

  .tabImg {
    width: 180px;
    height: 35px;
    margin: auto;
  }

  :deep(.el-tabs__item) {
    padding: 0 5px;
    height: auto;
    width: 240px;
  }

  :deep(.el-tabs__active-bar) {
    background-color: #00843d;
  }

  .bgGreen {
    background-color: #4b7902;
    padding: 40px 10px;
    color: #fff;
    display: flex;

    .MOMimg {
      width: 788px;
      height: 520px;
    }

    .textBox {
      width: calc(100% - 788px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 22px;

      & > :first-child {
        font-size: 32px;
        font-weight: bold;
      }

      & > :nth-of-type(2) {
        line-height: 36px;
      }

      & > :nth-of-type(3) {
        font-weight: bold;
      }

      & > :last-child {
        font-size: 16px;

        &:hover {
          cursor: pointer;
        }
      }

      .worksBox {
        & > div {
          width: 300px;
          height: 55px;
          line-height: 55px;
          text-align: center;
          background: #fff;
          color: #4b7902;
          display: inline-block;
          margin: 15px 20px;
          font-weight: normal;
          font-size: 16px;
          border-radius: 5px;
        }
      }
    }

    & > div {
      margin: 0 15px;
    }
  }
  .caseBox {
    // background-color: #ddd;
    :deep(.el-skeleton) {
      display: flex;
      justify-content: center;
    }
    .projectBox {
      width: 1640px;
      margin: auto;
      & > div {
        display: inline-block;
        margin: 20px 120px 20px 0;
        width: 320px;
      }
      & > div:nth-child(4n) {
        margin-right: 0;
      }

      .project {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 20px;
        &:hover {
          cursor: pointer;
        }

        & > :first-child {
          display: flex;
          align-items: flex-end;

          img {
            height: 35px;
          }

          div {
            font-weight: bold;
          }
        }

        & > :nth-of-type(2) {
          color: #fff;
          background: #548235;
          width: 100%;
          text-align: center;
          font-weight: bold;
          padding: 5px 0;
        }

        & > :last-child {
          width: 100%;
        }
      }
    }
  }

  .newsBox {
    margin: 10px 40px;
    border-bottom: 1px solid #548235;
    padding: 10px;
    &:hover {
      cursor: pointer;
    }
    .newsText {
      display: flex;

      & > div {
        padding: 5px 20px;
        line-height: 2rem;
        // background: #f5f5f5;
        display: flex;
        align-items: center;
        width: 100%;
      }

      .ellipsis {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .newsTitle {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 10px;

      & > :first-child {
        font-weight: bold;
        font-size: 24px;
      }
    }
  }
}
:deep(.el-button) {
  &:hover {
    background: rgba(200, 253, 164, 0.281);
    border-color: #6aa344;
    color: #548235;
  }
}
:deep(.el-button--primary) {
  background-color: #548235;
  border: #548235;
  &:hover {
    background: #6aa344;
    border-color: #6aa344;
    color: #fff;
  }
}
:deep(.el-carousel__arrow) {
  width: 70px;
  height: 70px;
  font-size: 45px;
}
</style>
