// http.js
import axios from 'axios';
 
const instance = axios.create({
    // baseURL: 'http://103.139.1.51:12301', // 根据项目情况修改baseURL
    timeout: 50000, // 超时时间
});

// 请求拦截器
instance.interceptors.request.use(
  config => {
    // 在请求发送前可以做一些通用处理，比如添加请求头
    // config.headers['Authorization'] = 'Bearer ' + getToken(); // 添加 token
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  response => {
    // 在响应返回后可以做一些通用处理，比如统一处理错误
    return response.data;
  },
  error => {
    // 统一处理错误
    if (error.response) {
      // 请求已发出，但服务器响应的状态码不在 2xx 范围内
      // console.error('Error status:', error.response.status);
      // console.error('Error data:', error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // 请求发出了，但没有收到响应
      // console.error('Error request:', error.request);
      return Promise.reject('No response received from server');
    } else {
      // 在设置请求时触发错误
      // console.error('Error:', error.message);
      return Promise.reject(error.message);
    }
  }
);

// 封装GET请求
export const get = (url, params) => {
  return instance.get(url, {
    params: params
  });
};

// 封装POST请求
export const post = (url, data) => {
  return instance.post(url, data);
};

// 导出请求方法
export default {
  get,
  post,
};
